<div class="flex flex-row items-center justify-between py-2">
  Which facility required the lumper fee?
  <div class="p-2 flex flex-col flex-1">
    <mat-select placeholder="Select a facility" [formControl]="facilityControl">
      <mat-option *ngFor="let stop of load.stops; trackBy: load.stops | trackByKey: 'id'" [value]="stop.facilityId">
        {{ stop.facilityName }}
      </mat-option>
    </mat-select>
  </div>
  <button color="primary" mat-raised-button class="ml-2" *ngIf="displayDispute" (click)="disputeLumper($event)">
    Dispute Lumper Charges
  </button>
  <ng-template *ngIf="lumperIsAllowed">
    <ng-container>
      <div>
        <button mat-flat-button color="accent" [mat-menu-trigger-for]="uploadType">Upload Documents</button>
        <mat-menu #uploadType>
          <button mat-menu-item (click)="promptUploadFile('other_image', 'Lumper Files')">
            Upload Lumper Documents
          </button>
        </mat-menu>
      </div>
    </ng-container>

    <ng-template #uploadFile>
      <div mat-dialog-title>Upload {{ (imageType$ | async)?.name }}</div>
      <mat-dialog-content>
        <div>
          <div class="font-bold text-lg mb-2">
            Please do not upload anything other than the {{ (imageType$ | async)?.name }}.
          </div>
          <div class="mb-2">
            If you are uploading a PDF and have bundled
            <span class="font-semibold">multiple different documents into one PDF</span>, please separate them before
            uploading. Otherwise you may be subject to a manual processing fee. Our shipping partners requires these
            documents individually.
          </div>
          <div class="mb-2">
            <span class="font-semibold">Attaching unnecessary documents</span> (such as a ratecon attached to the BOL)
            may also result in a manual processing fee.
          </div>
        </div>
        <ngx-file-drop
          [showBrowseBtn]="true"
          dropZoneLabel="Drop files here"
          contentClassName="flex flex-col w-full h-full items-center"
          dropZoneClassName=""
          (onFileDrop)="setNextFile($event)"
        >
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div *ngIf="filesToUpload$ | async as file">
              <div *ngFor="let f of file; trackBy: file | trackByKey: null">
                {{ f }}
              </div>
            </div>
            <button mat-flat-button type="button" color="accent" class="w-full mb-1" (click)="openFileSelector()">
              Browse Files
            </button>
          </ng-template>
        </ngx-file-drop>
      </mat-dialog-content>
      <mat-dialog-actions class="flex flex-row justify-between">
        <button mat-flat-button mat-dialog-close color="accent">Close</button>
        <button mat-flat-button color="primary" (click)="submitFile()">
          <span class="text-white">Upload</span>
        </button>
      </mat-dialog-actions>
    </ng-template>
  </ng-template>
</div>
<div *ngIf="lumperPrompt" class="text-orange-500 text-lg">
  {{ lumperPrompt }}
</div>
