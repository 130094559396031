import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { NetworkableDestroyableComponent } from '../../networkable-destroyable.component';
import {
  TourLoadCharge,
  TourLoadLineItemDisputes,
} from '../../../../modules/carrier/carrier-loads/carrier-loads.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadBillingService } from '../../../services/load-billing.service';
import { LohiLoad } from '../../../global-types';
import { Observable } from 'rxjs';

@Component({
  selector: 'td-load-billing-charge-dispute',
  templateUrl: './load-billing-charge-dispute.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadBillingChargeDisputeComponent extends NetworkableDestroyableComponent {
  public loadInDispute: LohiLoad;
  public charges: TourLoadCharge[];
  public oldLineHaulRate: number;
  public oldDeadheadRate: number;
  public oldLumperRate: number;
  public oldDetentionRate: number;
  public oldLayoverRate: number;

  public totalCharges$ = this.lbs.tourLoadCharges$;
  public totalDisputes$: Observable<TourLoadLineItemDisputes[]> = this.lbs.disputedChargesForLoad$;

  public disputedLumper: boolean = false;
  public disputedDetention: boolean = false;
  public disputedLayover: boolean = false;
  public disputedDeadhead: boolean = false;
  public disputedLineHaul: boolean = false;
  public disputedStopType: boolean = false;
  public disputedAdditionalStops: boolean = false;
  public totalDisputesReturned: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) load: LohiLoad,
    private lbs: LoadBillingService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
    this.loadInDispute = load;
    this.totalCharges$.subscribe((charges) => {
      this.charges = charges;
      for (const charge of charges) {
        if (charge.chargeType === 'line_haul') {
          this.oldLineHaulRate = charge.totalCents;
        } else if (charge.chargeType === 'lumper') {
          this.oldLumperRate = charge.totalCents;
        } else if (charge.chargeType === 'detention') {
          this.oldDetentionRate = charge.totalCents;
        } else if (charge.chargeType === 'layover') {
          this.oldLayoverRate = charge.totalCents;
        }
      }
      if (this.oldDeadheadRate === undefined) {
        this.oldDeadheadRate = 0;
      }
      if (this.oldLumperRate === undefined) {
        this.oldLumperRate = 0;
      }
      if (this.oldDetentionRate === undefined) {
        this.oldDetentionRate = 0;
      }
      if (this.oldLayoverRate === undefined) {
        this.oldLayoverRate = 0;
      }
      if (this.oldLineHaulRate === undefined) {
        this.oldLineHaulRate = 0;
      }
    });
    this.totalDisputes$.subscribe((disputes) => {
      for (const dispute of disputes) {
        if (dispute.accessorialChargeType === null) {
          if (dispute.nonAccessorialChargeType === 'line_haul') {
            this.disputedLineHaul = true;
          } else {
            this.disputedStopType = true;
          }
        } else {
          if (dispute.accessorialChargeType === 'lumper') {
            this.disputedLumper = true;
          } else if (dispute.accessorialChargeType === 'detention') {
            this.disputedDetention = true;
          } else if (dispute.accessorialChargeType === 'layover') {
            this.disputedLayover = true;
          } else if (dispute.accessorialChargeType === 'deadhead') {
            this.disputedDeadhead = true;
          }
          this.disputedAdditionalStops = true;
          this.cdr.detectChanges();
        }
      }
      this.totalDisputesReturned = false;
      this.cdr.detectChanges();
    });
  }
}
