import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, lastValueFrom, Observable, switchMap, throwError } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import {
  LohiLoadAllowedChargeType,
  TourBillingDetails,
  TourLoadCharge,
  TourLoadLineItemDisputes,
  TourLoadPendingCharge,
  VortoTakeRate,
} from '../../modules/carrier/carrier-loads/carrier-loads.service';
import { environment } from '../../../environments/environment';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { FileUploadType, LoHiLoadEditableInfo, LohiLoadStop, ProductLoad } from '../global-types';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SimpleFinn } from '../utilities/simpleFinn';

export interface ApprovePrecheckResponse {
  uploadFine: {
    hasFine: boolean;
    hasPass: boolean;
  };
}

@Injectable({
  providedIn: 'root',
})
export class LoadBillingService {
  private productLoad$$ = new BehaviorSubject<ProductLoad>(null);
  public productLoad$: Observable<ProductLoad> = this.productLoad$$.pipe(shareReplay(1));
  public loadID$ = this.productLoad$.pipe(map((load) => load?.id));
  private tourLoadCharges$$ = new BehaviorSubject<TourLoadCharge[]>([]);
  public tourLoadCharges$: Observable<TourLoadCharge[]> = this.tourLoadCharges$$.pipe(shareReplay(1));

  private tourLoadPendingCharges$$ = new BehaviorSubject<TourLoadPendingCharge[]>([]);
  public tourLoadPendingCharges$: Observable<TourLoadPendingCharge[]> = this.tourLoadPendingCharges$$.pipe(
    shareReplay(1),
  );

  private vortoTakeRate$$ = new BehaviorSubject<VortoTakeRate>(null);
  public vortoTakeRate$: Observable<VortoTakeRate> = this.vortoTakeRate$$.pipe(shareReplay(1));

  private tourLoadBillingDetails$$ = new BehaviorSubject<TourBillingDetails | null>(null);
  public tourLoadBillingDetails$: Observable<TourBillingDetails> = this.tourLoadBillingDetails$$.pipe(shareReplay(1));

  private chargeTypes: SimpleFinn<LohiLoadAllowedChargeType[]>;

  public get chargeTypes$(): Observable<LohiLoadAllowedChargeType[]> {
    return this.chargeTypes.get$();
  }

  constructor(private http: HttpClient, private httpBackend: HttpBackend, private afAuth: AngularFireAuth) {
    this.chargeTypes = new SimpleFinn<LohiLoadAllowedChargeType[]>([], this.loadChargeTypes);
    this.loadID$.subscribe((loadId) => {
      this.disputedChargesForLoad = new SimpleFinn<TourLoadLineItemDisputes[]>([], () =>
        this.listOfBillingDisputes(loadId),
      );
    });
  }

  private async loadProductLoad(loadId: string): Promise<ProductLoad> {
    try {
      return lastValueFrom(this.http.get<ProductLoad>(`${environment.api}/v2/load/${loadId}`));
    } catch (e) {
      return null;
    }
  }

  private async refreshProductLoad(loadId: string) {
    const details = await this.loadProductLoad(loadId);
    this.productLoad$$.next(details);
  }

  private async loadTourLoadCharges(loadId: string) {
    try {
      return await lastValueFrom(
        this.http
          .get<{ charges: TourLoadCharge[]; takeRate: VortoTakeRate; pendingCharges: TourLoadPendingCharge[] }>(
            `${environment.api}/v2/load/${loadId}/tour_billing/charge`,
          )
          .pipe(map((resp) => resp)),
      );
    } catch (e) {
      return null;
    }
  }

  private async refreshTourLoadCharges(loadId: string) {
    const charges = await this.loadTourLoadCharges(loadId);
    this.tourLoadCharges$$.next(charges?.charges ?? []);
    this.tourLoadPendingCharges$$.next(charges?.pendingCharges ?? []);
    this.vortoTakeRate$$.next(charges?.takeRate ?? null);
  }

  private async loadTourLoadBillingDetails(loadId: string): Promise<TourBillingDetails> {
    try {
      return await lastValueFrom(
        this.http
          .get<{ details: TourBillingDetails }>(`${environment.api}/v2/load/${loadId}/tour_billing`)
          .pipe(map((resp) => resp.details)),
      );
    } catch (e) {
      return null;
    }
  }

  public async runDisputeStopType(loadId: string, facilityID: string, oldRate: number): Promise<Record<string, any>> {
    return this.disputeStopTypeCall(loadId, facilityID, oldRate);
  }

  public async disputeStopTypeCall(loadId: string, facilityID: string, oldRate: number): Promise<Record<string, any>> {
    try {
      const response = await lastValueFrom(
        this.http.post<Record<string, any>>(
          `${environment.api}/v2/load/${loadId}/tour_billing/charge/dispute/stop_type?facilityID=${facilityID}&oldRate=${oldRate}`,
          {},
        ),
      );
      return response;
    } catch (e) {
      return null;
    }
  }
  public async runDisputeStopCharges(loadId: string): Promise<Record<string, any>> {
    return this.disputeStopChargesCall(loadId);
  }

  public async disputeStopChargesCall(loadId: string): Promise<Record<string, any>> {
    try {
      const response = await lastValueFrom(
        this.http.post<Record<string, any>>(
          `${environment.api}/v2/load/${loadId}/tour_billing/charge/dispute/stop_charges`,
          {},
        ),
      );
      return response;
    } catch (e) {
      return null;
    }
  }

  public async runDisputeLumper(loadId: string, facs: string, oldRate: number): Promise<Record<string, any>> {
    return this.disputeLumperCall(loadId, facs, oldRate);
  }

  private async disputeLumperCall(loadId: string, facs: string, oldRate: number): Promise<Record<string, any>> {
    try {
      const response = await lastValueFrom(
        this.http.post<Record<string, any>>(
          `${environment.api}/v2/load/${loadId}/tour_billing/charge/dispute/lumper?oldRate=${oldRate}`,
          {
            facilities: facs,
          },
        ),
      );
      return response;
    } catch (e) {
      return null;
    }
  }

  public async runDisputeLayover(loadId: string, oldRate: number): Promise<Record<string, any>> {
    return this.disputeLayoverCall(loadId, oldRate);
  }

  private async disputeLayoverCall(loadId: string, oldRate: number): Promise<Record<string, any>> {
    try {
      const response = await lastValueFrom(
        this.http.post<Record<string, any>>(
          `${environment.api}/v2/load/${loadId}/tour_billing/charge/dispute/layover?oldRate=${oldRate}`,
          { loadId: loadId },
        ),
      );
      return response;
    } catch (e) {
      return null;
    }
  }

  // Deadhead
  public async runDisputeDeadhead(loadId: string, oldRate: number): Promise<Record<string, any>> {
    return this.disputeDeadheadCall(loadId, oldRate);
  }

  public async runDisputeDetention(loadId: string, stops: number[], oldRate: number): Promise<Record<string, any>> {
    return this.disputeDetentionCall(loadId, stops, oldRate);
  }

  private async disputeDetentionCall(
    loadId: string,
    inputStops: number[],
    oldRate: number,
  ): Promise<Record<string, any>> {
    try {
      const response = await lastValueFrom(
        this.http.post<Record<string, any>>(
          `${environment.api}/v2/load/${loadId}/tour_billing/charge/dispute/detention?oldRate=${oldRate}`,
          { stopID: inputStops },
        ),
      );
      return response;
    } catch (e) {
      return null;
    }
  }

  private disputedChargesForLoad: SimpleFinn<TourLoadLineItemDisputes[]>;

  public get disputedChargesForLoad$() {
    return this.disputedChargesForLoad.get$();
  }

  private async listOfBillingDisputes(loadID: string): Promise<TourLoadLineItemDisputes[]> {
    try {
      const response = lastValueFrom(
        this.http.get<Promise<TourLoadLineItemDisputes[]>>(
          `${environment.api}/v2/load/${loadID}/tour_billing/charge/dispute/list`,
        ),
      );
      console.log(response);
      return response;
    } catch (e) {
      return null;
    }
  }

  public async runDisputeLinehaul(loadId: string, oldRate: number): Promise<Record<string, any>> {
    return this.disputeLineHaulCall(loadId, oldRate);
  }

  private async disputeDeadheadCall(loadId: string, oldRate: number): Promise<Record<string, any>> {
    try {
      const response = await lastValueFrom(
        this.http.post<Record<string, any>>(
          `${environment.api}/v2/load/${loadId}/tour_billing/charge/dispute/mileage?mileageCharge=deadhead&oldRate=${oldRate}`,
          { loadId: loadId },
        ),
      );
      return response;
    } catch (e) {
      return null;
    }
  }

  private async disputeLineHaulCall(loadId: string, oldRate: number): Promise<Record<string, any>> {
    try {
      const response = await lastValueFrom(
        this.http.post<Record<string, any>>(
          `${environment.api}/v2/load/${loadId}/tour_billing/charge/dispute/mileage?mileageCharge=line_haul&oldRate=${oldRate}`,
          { loadId: loadId },
        ),
      );
      return response;
    } catch (e) {
      return null;
    }
  }
  private async refreshTourLoadBillingDetails(loadId: string) {
    const details = await this.loadTourLoadBillingDetails(loadId);
    this.tourLoadBillingDetails$$.next(details);
  }

  public async uploadTourChargeFile(charge: TourLoadCharge, fileToUpload: File): Promise<boolean> {
    const idToken = await lastValueFrom(this.afAuth.idToken.pipe(take(1)));
    try {
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      // Use custom one because we need this thing to generate the headers for multi part
      const client = new HttpClient(this.httpBackend);
      const response = await lastValueFrom(
        client.post<{ charges: TourLoadCharge[]; takeRate: VortoTakeRate }>(
          `${environment.api}/v2/load/${charge.loadID}/tour_billing/charge/${charge.id}/upload`,
          formData,
          {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              Authorization: `Bearer ${idToken}`,
            },
          },
        ),
      );
      this.refreshLoad(charge.loadID);
      return true;
    } catch (err) {
      return false;
    }
  }

  public async uploadTourPendingChargeFile(pendingCharge: TourLoadPendingCharge, fileToUpload: File): Promise<boolean> {
    const idToken = await lastValueFrom(this.afAuth.idToken.pipe(take(1)));
    const load = this.productLoad$$.value;
    try {
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      formData.append('pendingChargeId', `${pendingCharge.id}`);
      // Use custom one because we need this thing to generate the headers for multi part
      const client = new HttpClient(this.httpBackend);
      await lastValueFrom(
        client.post<{ charges: TourLoadCharge[]; takeRate: VortoTakeRate }>(
          `${environment.api}/v2/load/${load.id}/tour_billing/charge/upload_file_to_pending_charge`,
          formData,
          {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              Authorization: `Bearer ${idToken}`,
            },
          },
        ),
      );

      this.refreshLoad(load.id);
      return true;
    } catch (err) {
      return false;
    }
  }

  public async removePendingChargeFile(uploadId: number): Promise<boolean> {
    try {
      const load = this.productLoad$$.value;
      await lastValueFrom(
        this.http.delete<{ charges: TourLoadCharge[]; takeRate: VortoTakeRate }>(
          `${environment.api}/v2/load/${load.id}/tour_billing/charge/remove_pending_charge_upload/${uploadId}`,
        ),
      );

      this.refreshLoad(load.id);
      return true;
    } catch (err) {
      return false;
    }
  }

  public async addTourCharge(
    lohiLoadId: string,
    chargeType: LohiLoadAllowedChargeType,
    cents: number,
  ): Promise<boolean> {
    try {
      const response = await lastValueFrom(
        this.http
          .post<{ charges: TourLoadCharge[]; takeRate: VortoTakeRate }>(
            `${environment.api}/v2/load/${lohiLoadId}/tour_billing/charge`,
            {
              id: chargeType.id,
              name: chargeType.displayName,
              cents,
            },
          )
          .pipe(map((resp) => resp.charges || [])),
      );
      this.refreshLoad(lohiLoadId);
      return true;
    } catch (err) {
      return false;
    }
  }

  public async requestNewCharge(
    lohiLoadId: string,
    allowedChargeTypeId: number,
    requestedCents: number,
    carrierNotes: string,
    chassisNumber: string,
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.post<{ charges: TourLoadCharge[]; takeRate: VortoTakeRate }>(
          `${environment.api}/v2/load/${lohiLoadId}/tour_billing/charge/request_add_charge`,
          {
            allowedChargeTypeId,
            requestedCents,
            carrierNotes,
            chassisNumber,
          },
        ),
      );
      this.refreshLoad(lohiLoadId);
      return true;
    } catch (err) {
      return false;
    }
  }

  public async requestUpdateCharge(
    lohiLoadId: string,
    chargeId: number,
    requestedCents: number,
    carrierNotes: string,
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put<{ charges: TourLoadCharge[]; takeRate: VortoTakeRate }>(
          `${environment.api}/v2/load/${lohiLoadId}/tour_billing/charge/${chargeId}/request_update_charge`,
          {
            requestedCents,
            carrierNotes,
          },
        ),
      );
      this.refreshLoad(lohiLoadId);
      return true;
    } catch (err) {
      return false;
    }
  }

  public async deletePendingCharge(pendingChargeId: number): Promise<boolean> {
    try {
      const load = this.productLoad$$.value;
      await lastValueFrom(
        this.http.delete<{ charges: TourLoadCharge[] }>(
          `${environment.api}/v2/load/${load.id}/tour_billing/charge/remove_pending_charge/${pendingChargeId}`,
        ),
      );
      this.refreshLoad(load.id);
      return true;
    } catch (err) {
      return false;
    }
  }

  public async editTourCharge(
    lohiLoadId: string,
    id: number,
    chargeTypeNameId: number,
    name: string,
    cents: number,
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http
          .put<{ charges: TourLoadCharge[] }>(`${environment.api}/v2/load/${lohiLoadId}/tour_billing/charge/${id}`, {
            chargeTypeNameId: `${chargeTypeNameId}`,
            name,
            cents,
          })
          .pipe(map((resp) => resp.charges || [])),
      );
      this.refreshLoad(lohiLoadId);
      return true;
    } catch (err) {
      return false;
    }
  }

  public async deleteTourCharge(lohiLoadId: string, id: number): Promise<boolean> {
    try {
      const response = await lastValueFrom(
        this.http
          .delete<{ charges: TourLoadCharge[] }>(`${environment.api}/v2/load/${lohiLoadId}/tour_billing/charge/${id}`)
          .pipe(map((resp) => resp.charges || [])),
      );
      this.refreshLoad(lohiLoadId);
      return true;
    } catch (err) {
      return false;
    }
  }

  public async deleteTourLinehaulCharge(lohiLoadId: string): Promise<boolean> {
    try {
      const response = await lastValueFrom(
        this.http
          .delete<{ charges: TourLoadCharge[] }>(
            `${environment.api}/v2/load/${lohiLoadId}/tour_billing/charge/linehaul`,
          )
          .pipe(map((resp) => resp.charges || [])),
      );
      this.refreshLoad(lohiLoadId);
      return true;
    } catch (err) {
      return false;
    }
  }

  public async deleteTourChargeFile(lohiLoadId: string, chargeID: number, fileID: number): Promise<boolean> {
    try {
      const response = await lastValueFrom(
        this.http
          .delete<{ charges: TourLoadCharge[] }>(
            `${environment.api}/v2/load/${lohiLoadId}/tour_billing/charge/${chargeID}/upload/${fileID}`,
          )
          .pipe(map((resp) => resp.charges || [])),
      );
      this.refreshLoad(lohiLoadId);
      return true;
    } catch (err) {
      return false;
    }
  }

  public async uploadFile(
    lohiLoadId: string,
    fileToUpload: File,
    category: FileUploadType,
    name?: string,
  ): Promise<boolean> {
    const idToken = await lastValueFrom(this.afAuth.idToken.pipe(take(1)));
    try {
      const formData = new FormData();
      formData.append('file', fileToUpload, name || fileToUpload.name);
      // Use custom one because we need this thing to generate the headers for multi part
      const client = new HttpClient(this.httpBackend);
      await lastValueFrom(
        client.post<null>(`${environment.api}/v2/load/${lohiLoadId}/upload`, formData, {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: `Bearer ${idToken}`,
          },
          params: {
            category,
          },
        }),
      );
      this.refreshLoad(lohiLoadId);
      return true;
    } catch (error) {
      return false;
    }
  }

  public async refreshLoad(lohiLoadId: string) {
    this.refreshTourLoadCharges(lohiLoadId);
    this.refreshTourLoadBillingDetails(lohiLoadId);
    this.refreshProductLoad(lohiLoadId);
  }

  public getEditableLoadInfo$(): Observable<LoHiLoadEditableInfo> {
    return this.productLoad$.pipe(
      take(1),
      switchMap((value) => {
        if (!value || !value.uniqueId) {
          return throwError(() => 'No Current Order');
        }
        return this.http.get<LoHiLoadEditableInfo>(`${environment.api}/v2/load/${value.uniqueId}/editable_info`);
      }),
    );
  }

  private loadChargeTypes = async () => {
    try {
      const results = await lastValueFrom(
        this.http.get<LohiLoadAllowedChargeType[]>(`${environment.api}/v2/load/charge_types`),
      );
      return (results || []).sort((a, b) => a.displayName.localeCompare(b.displayName));
    } catch (error) {
      return [];
    }
  };

  public async approveTourLoad(loadID: string): Promise<boolean> {
    try {
      const response = await lastValueFrom(
        this.http
          .post<{ details: TourBillingDetails }>(`${environment.api}/v2/load/${loadID}/tour_billing/admin_approve`, {})
          .pipe(map((resp) => resp.details)),
      );
      this.tourLoadBillingDetails$$.next(response);
      return true;
    } catch (err) {
      return false;
    }
  }

  public async approvePrecheck(loadID: string): Promise<ApprovePrecheckResponse> {
    try {
      const response = await lastValueFrom(
        this.http.get<ApprovePrecheckResponse>(`${environment.api}/v2/load/${loadID}/tour_billing/approve_precheck`),
      );
      return response;
    } catch (err) {
      return null;
    }
  }

  public async consumeBadUploadPass(loadID: string): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.post<null>(`${environment.api}/v2/load/${loadID}/tour_billing/consume_bad_upload_pass`, {}),
      );
      this.refreshLoad(loadID);
      return true;
    } catch (err) {
      return false;
    }
  }

  public async unapproveTourLoad(loadID: string): Promise<boolean> {
    try {
      const response = await lastValueFrom(
        this.http
          .post<{ details: TourBillingDetails }>(
            `${environment.api}/v2/load/${loadID}/tour_billing/admin_unapprove`,
            {},
          )
          .pipe(map((resp) => resp.details)),
      );
      this.tourLoadBillingDetails$$.next(response);
      return true;
    } catch (err) {
      return false;
    }
  }

  public async removeUploadFromLoad(loadId: string, fileId: number): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.delete<null>(`${environment.api}/v2/load/${loadId}/tour_billing/delete_upload/${fileId}`, {}),
      );
      this.refreshLoad(loadId);
      return true;
    } catch (err) {
      return false;
    }
  }
}
