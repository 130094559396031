import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { LoadingType, LohiLoadStop, SlimProduct, Unit } from '../../global-types';
import {
  addMinutesIfNotNull,
  getDateOrNullFromString,
  getUserShortTimezone,
  stringOrDateToDate,
} from '../../utilities/dateTime';
import { SnackBarService } from '../../services/snackbar.service';
import { MatDialogRef } from '@angular/material/dialog';
import { differenceInMinutes, isAfter } from 'date-fns';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, from, lastValueFrom, Observable, of, switchMap, takeUntil } from 'rxjs';
import { combineLatestWith, distinctUntilChanged, map, shareReplay, startWith, take } from 'rxjs/operators';
import { FusedObservable } from '../../utilities/fusedObservable';
import { AuthService, LoHiUserInfo } from '../../services/auth.service';
import {
  EditLoadStop,
  LoadService,
  TourCustomerFacilityForLoad,
  TrailersForLoad,
} from 'src/app/modules/internal/loads/load.service';
import { NetworkableDestroyableComponent } from '../networkable-destroyable.component';
import { ConstantsService } from '../../services/constants.service';
import { shareReplayComponentConfig } from '../../constants';
import { ShipperLoadService } from '../../../modules/internal/shipper-load/shipper-load.service';
import {
  DispatchPoolService,
  DispatchPoolWithConfig,
} from '../../../modules/internal/dispatch-pool/dispatch-pool.service';
import { FeatureFlagService } from '../../services/feature-flag.service';

type EditStopFormValue = Omit<Record<keyof EditLoadStop, any>, 'arrivalWindowMinutes'> & { arrivalWindowEndsAt: any };

@Component({
  selector: 'td-edit-load-stop',
  templateUrl: './edit-load-stop.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditLoadStopComponent extends NetworkableDestroyableComponent {
  public _stop: LohiLoadStop;
  public editStopForm: FormGroup<{
    id: FormControl<number>;
    sequence: FormControl<number>;
    lng: FormControl<number>;
    lat: FormControl<number>;
    type: FormControl<'pickup' | 'dropoff' | 'empty_asset_pickup' | 'empty_asset_dropoff'>;
    loadingType: FormControl<LoadingType>;
    title: FormControl<string>;
    address: FormControl<string>;
    city: FormControl<string>;
    state: FormControl<string>;
    zipCode: FormControl<string>;
    phone: FormControl<string>;
    arrivalWindowStartsAt: FormControl<string>;
    arrivalWindowEndsAt: FormControl<string>;
    arrivalWindowExtremaStartsAt: FormControl<string>;
    arrivalWindowExtremaEndsAt: FormControl<string>;
    originalArrivalWindowStartsAt: FormControl<string>;
    details: FormControl<string>;
    directions: FormControl<string>;
    commodity: FormControl<string>;
    quantity: FormControl<number>;
    unit: FormControl<number>;
    detentionFreeMinutes: FormControl<number>;
    referenceNumber: FormControl<string>;
    appointmentTrackingNumber: FormControl<string>;
    facilityId: FormControl<string>;
    expectedAt: FormControl<string>;
    isCPG: FormControl<boolean>;
    isDrayage: FormControl<boolean>;
    attachEmptyTrailerPickup: FormControl<boolean>;
    productId: FormControl<string | null>;
    emptyTrailerPickupID: FormControl<number | null>;
  }>;

  public timezone = getUserShortTimezone();
  public units$: Observable<Unit[]>;
  public facilities$: Observable<TourCustomerFacilityForLoad[]>;

  public siteTime: {
    earliestPossibleArrivalAt$: Observable<string>;
    arrivalWindowStartsAt$: Observable<string>;
    arrivalWindowEndsAt$: Observable<string>;
    latestPossibleArrivalAt$: Observable<string>;
    originalArrivalWindowStartsAt$: Observable<string>;
    expectedAt$: Observable<string>;
  };

  public selectedFacility$: Observable<TourCustomerFacilityForLoad>;
  public facilitySearch = new UntypedFormControl();
  public filteredFacilities$: Observable<TourCustomerFacilityForLoad[]>;
  private userInfo$: Observable<LoHiUserInfo>;
  public trailers$: Observable<TrailersForLoad>;
  public trailerSearch = new FormControl<string>(null);

  @Input()
  public set customerID(customerID: string) {
    if (!customerID) {
      return;
    }
    this.customerId$$.next(customerID);
  }

  private customerId$$ = new BehaviorSubject<string>(null);
  public customerId$ = this.customerId$$.pipe(shareReplay(shareReplayComponentConfig));
  private products$: Observable<SlimProduct[]>;
  public productSearch = new UntypedFormControl();
  public filteredProducts$: Observable<SlimProduct[]>;
  public shouldSelectProduct$: Observable<boolean>;

  @Input()
  public set stop(s: LohiLoadStop) {
    if (!s || this.editStopForm) {
      return;
    }
    this._stop = s;
    this.setupForm(s);
    this.isEAP$$.next(s.type === 'empty_asset_pickup');
  }

  private isEAP$$ = new BehaviorSubject<boolean>(false);

  @Output() public wasUpdated = new EventEmitter<boolean>();

  public canAddEap$ = this.loadService.canAddEAP$;
  public canAddEapAtFacility$: Observable<boolean>;
  public loadingEapSummary$ = this.loadService.eapSummaryLoading$;
  public eapSummary$ = this.loadService.eapSummary$;
  public dispatchPool$: Observable<DispatchPoolWithConfig>;

  constructor(
    private authService: AuthService,
    private loadService: LoadService,
    private fb: UntypedFormBuilder,
    private constantsService: ConstantsService,
    private cd: ChangeDetectorRef,
    private snackbar: SnackBarService,
    private shipperLoadService: ShipperLoadService,
    private dispatchPoolService: DispatchPoolService,
    private featureFlagService: FeatureFlagService,
    @Optional() private dRef: MatDialogRef<EditLoadStopComponent>,
  ) {
    super();
    this.userInfo$ = this.authService.userInfo$;
    this.units$ = this.constantsService.units$;
    this.facilities$ = this.loadService.facilitiesForLoad$;
    this.filteredFacilities$ = new FusedObservable(
      this.loadService.facilitiesForLoad$,
      this.facilitySearch.valueChanges,
      {
        distance: 1000,
        keys: ['id', 'name', 'addressLine1', 'addressLine2', 'addressCity', 'addressState', 'addressZipCode'],
        location: 0,
        minMatchCharLength: 1,
        shouldSort: true,
        threshold: 0.2,
      },
    ).fused$;
  }

  public override networkActive$ = combineLatest([
    this.networkActive$$,
    this.loadService.canAddEAPLoading$,
    this.loadService.eapSummaryLoading$,
  ]).pipe(map((bools) => bools.some((b) => b)));

  public async updateStop() {
    if (this.networkActive$$.value) {
      return;
    }

    if (this.editStopForm.invalid) {
      this.editStopForm.markAllAsTouched();
      return;
    }

    const formValue = this.editStopForm.getRawValue() as EditStopFormValue;
    try {
      this.networkActive$$.next(true);

      const success = await this.loadService.editStopDetails(this._stop.loadId, formValueToEditLoadStop(formValue));
      if (success) {
        this.snackbar.showMessage('Stop updated');
        this.wasUpdated.emit(true);
        this.dRef?.close(true);
      }
    } finally {
      this.networkActive$$.next(false);
    }
  }

  public async removeStop() {
    if (this.networkActive$$.value) {
      return;
    }

    try {
      this.isActive = true;
      const success = await this.loadService.deleteStopFromLoad(this._stop.loadId, this._stop.id);
      if (success) {
        this.snackbar.showMessage('Stop Removed');
        this.wasUpdated.emit(true);
        this.dRef?.close(true);
      }
    } finally {
      this.isActive = false;
    }
  }

  private setupForm(stop: LohiLoadStop) {
    const formInput: EditStopFormValue = {
      facilityId: [stop.facilityId, [Validators.required]],
      id: [stop.id, []],
      sequence: [stop.sequence, []],
      lng: [stop.lngLat.x, [Validators.required]],
      lat: [stop.lngLat.y, [Validators.required]],
      type: [stop.type, [Validators.required]],
      loadingType: [stop.loadingType, [Validators.required]],
      title: [stop.title, stop.facilityId ? [] : [Validators.required]],
      address: [stop.address, []],
      city: [stop.city, []],
      state: [stop.state, []],
      zipCode: [stop.zipCode, []],
      phone: [stop.phone, []],
      arrivalWindowExtremaStartsAt: [stop.isCPG || stop.isDrayage ? stop.arrivalWindowStartsAtExtrema : null],
      arrivalWindowStartsAt: [getDateOrNullFromString(stop.arrivalWindowStartsAt)],
      arrivalWindowEndsAt: [addMinutesIfNotNull(stop.arrivalWindowStartsAt, stop.arrivalWindowMinutes)?.toISOString()],
      arrivalWindowExtremaEndsAt: [stop.isCPG || stop.isDrayage ? stop.arrivalWindowEndsAtExtrema : null],
      originalArrivalWindowStartsAt: [getDateOrNullFromString(stop.originalArrivalWindowStartsAt), []],
      expectedAt: [getDateOrNullFromString(stop.expectedAt), []],
      appointmentTrackingNumber: [stop.appointmentTrackingNumber, []],
      details: [stop.details, []],
      directions: [stop.directions, []],
      commodity: [stop.commodity, [Validators.required]],
      quantity: [stop.quantity, [Validators.required, Validators.min(1)]],
      unit: [stop.unit.id, [Validators.required, Validators.min(1)]],
      referenceNumber: [stop.referenceNumber, []],
      detentionFreeMinutes: [stop.detentionFreeMinutes, [Validators.required, Validators.min(0)]],
      isCPG: [stop?.isCPG, []],
      isDrayage: [stop?.isDrayage, []],
      attachEmptyTrailerPickup: [stop.attachEmptyTrailerPickup, []],
      productId: [stop.productId, []],
      emptyTrailerPickupID: [stop.emptyTrailerPickupId, []],
    };
    this.editStopForm = this.fb.group(formInput);
    this.cd.markForCheck();

    let getSiteTime$ = (control: FormControl): Observable<string> =>
      control.valueChanges.pipe(startWith(control.value), map(this.calculateStopWindowStartDisplayTime.bind(this)));

    this.siteTime = {
      arrivalWindowStartsAt$: getSiteTime$(this.editStopForm.controls.arrivalWindowStartsAt),
      arrivalWindowEndsAt$: getSiteTime$(this.editStopForm.controls.arrivalWindowEndsAt),
      earliestPossibleArrivalAt$: getSiteTime$(this.editStopForm.controls.arrivalWindowExtremaStartsAt),
      latestPossibleArrivalAt$: getSiteTime$(this.editStopForm.controls.arrivalWindowExtremaEndsAt),
      originalArrivalWindowStartsAt$: getSiteTime$(this.editStopForm.controls.originalArrivalWindowStartsAt),
      expectedAt$: getSiteTime$(this.editStopForm.controls.expectedAt),
    };

    this.selectedFacility$ = combineLatest([
      this.editStopForm.controls.facilityId.valueChanges.pipe(startWith(stop.facilityId)),
      this.facilities$,
    ]).pipe(
      map(([id, facilities]) => {
        if (!id || !facilities) {
          return null;
        }
        return facilities.find((f) => f.id === id);
      }),
    );

    this.products$ = this.customerId$.pipe(
      startWith(this.customerId$$.value),
      switchMap((value) => {
        if (!value) {
          return of([] as SlimProduct[]);
        }
        return this.shipperLoadService.getCustomerProducts$(value);
      }),
      shareReplay(shareReplayComponentConfig),
    );
    this.filteredProducts$ = new FusedObservable(this.products$, this.productSearch.valueChanges, ['name']).fused$;
    this.shouldSelectProduct$ = this.products$.pipe(
      map((v) => !!v.length && !!stop.productId),
      startWith(!!stop.productId),
      shareReplay(shareReplayComponentConfig),
    );

    this.trailers$ = this.loadService
      .getTrailersForPickup$(this._stop.loadId, this._stop.id)
      .pipe(shareReplay(shareReplayComponentConfig));

    combineLatest([this.editStopForm.controls.productId.valueChanges.pipe(startWith(stop.productId)), this.products$])
      .pipe(
        map(([id, products]) => {
          if (!id || !products) {
            return null;
          }
          return products.find((f) => f.id === id);
        }),
      )
      .subscribe((product) => {
        if (product) {
          this.editStopForm.controls.commodity.setValue(product.name);
          this.editStopForm.controls.unit.setValue(product.unitId);
        }
      });

    this.editStopForm.valueChanges.pipe(takeUntil(this.destroy$$)).subscribe((v: EditStopFormValue) => {
      this.editStopForm.controls.arrivalWindowStartsAt.markAllAsTouched();
      this.editStopForm.controls.arrivalWindowEndsAt.markAllAsTouched();
      this.editStopForm.controls.arrivalWindowExtremaStartsAt.markAllAsTouched();
      this.editStopForm.controls.arrivalWindowExtremaEndsAt.markAllAsTouched();
      this.editStopForm.controls.arrivalWindowStartsAt.setErrors(null);
      this.editStopForm.controls.arrivalWindowEndsAt.setErrors(null);
      this.editStopForm.controls.arrivalWindowExtremaStartsAt.setErrors(null);
      this.editStopForm.controls.arrivalWindowExtremaEndsAt.setErrors(null);

      if (
        v.arrivalWindowStartsAt &&
        v.arrivalWindowEndsAt &&
        isAfter(stringOrDateToDate(v.arrivalWindowStartsAt), stringOrDateToDate(v.arrivalWindowEndsAt))
      ) {
        this.editStopForm.controls.arrivalWindowStartsAt.setErrors({ arrivalWindowEndsAt: true });
        this.editStopForm.controls.arrivalWindowEndsAt.setErrors({ arrivalWindowStartsAt: true });
      }

      if (
        v.arrivalWindowExtremaStartsAt &&
        v.arrivalWindowExtremaEndsAt &&
        isAfter(stringOrDateToDate(v.arrivalWindowExtremaStartsAt), stringOrDateToDate(v.arrivalWindowExtremaEndsAt))
      ) {
        this.editStopForm.controls.arrivalWindowExtremaStartsAt.setErrors({ arrivalWindowExtremaEndsAt: true });
        this.editStopForm.controls.arrivalWindowExtremaEndsAt.setErrors({ arrivalWindowExtremaStartsAt: true });
      }
    });

    this.disableOATIfNeeded();
    this.canAddEap$.pipe(takeUntil(this.destroy$$), distinctUntilChanged()).subscribe((canAddEAP) => {
      if (!canAddEAP) {
        this.editStopForm.controls.facilityId.disable();
        this.editStopForm.controls.type.disable();
      } else {
        this.editStopForm.controls.facilityId.enable();
        this.editStopForm.controls.type.enable();
      }
    });

    this.editStopForm.controls.loadingType.valueChanges
      .pipe(takeUntil(this.destroy$$), startWith(stop.loadingType))
      .subscribe((loadingType) => {
        if (loadingType === 'live') {
          this.editStopForm.controls.attachEmptyTrailerPickup.setValue(false);
        }
      });

    this.dispatchPool$ = this.loadService.load$.pipe(
      switchMap((l) => from(this.dispatchPoolService.getDispatchPool(l.lohi.poolId))),
      shareReplay(shareReplayComponentConfig),
    );

    this.canAddEapAtFacility$ = this.canAddEap$.pipe(
      combineLatestWith(
        this.selectedFacility$,
        this.eapSummary$,
        this.dispatchPool$,
        this.loadService.load$,
        this.featureFlagService.isFlagActive$('eapSuperUser'),
      ),
      map(([canAddEapForLoad, selectedFacility, eapSummary, dispatchPool, load, eapSuperUser]) => {
        if (eapSuperUser) return true;
        if (!canAddEapForLoad) return false;

        const poolConfig = dispatchPool.configs.find((c) => c.customerID === load.lohi.shipperId);
        if (!poolConfig?.blockManualEapsAgainstRecommendations) {
          return true;
        }

        if (!eapSummary || !selectedFacility) return false;

        if (eapSummary.bestFacility === null) return false;
        if (eapSummary.bestFacility?.id === selectedFacility.id) return true;

        const selectedFacilityStats = eapSummary.potentialFacilities.find((f) => f.id === selectedFacility.id);
        if (!selectedFacilityStats) return false;

        if (
          selectedFacilityStats.isBanned ||
          !selectedFacilityStats.isOpen ||
          selectedFacilityStats.totalEmptiesAvailable < 1 ||
          selectedFacilityStats.probResults.recentFailedEAP
        )
          return false;

        return true;
      }),
      startWith(false),
    );
  }

  private calculateStopWindowStartDisplayTime(enteredDate: Date | string): string {
    if (!enteredDate) {
      return null;
    }

    if (!this._stop.timeZone) {
      return null;
    }

    let asDateTime: DateTime;

    if (typeof enteredDate === 'string' || enteredDate instanceof String) {
      asDateTime = DateTime.fromISO(enteredDate as string).setZone(this._stop.timeZone.name);
    } else {
      asDateTime = DateTime.fromJSDate(enteredDate).setZone(this._stop.timeZone.name);
    }

    return asDateTime.toFormat('M/d/yyyy, T ZZZZ');
  }

  private async disableOATIfNeeded() {
    const user = await lastValueFrom(this.userInfo$.pipe(take(1)));
    if (!user.permissions.includes('tour-dispatcher-manager')) {
      this.editStopForm.controls.originalArrivalWindowStartsAt.disable();
    }
  }
}

const formValueToEditLoadStop = (fv: EditStopFormValue): EditLoadStop => {
  const copy = {
    ...fv,
  };
  if (!fv.isCPG && !fv.isDrayage) {
    delete copy.arrivalWindowExtremaStartsAt;
    delete copy.arrivalWindowExtremaEndsAt;
  }
  delete copy.arrivalWindowEndsAt;
  if (fv.loadingType === 'live') {
    // this must be false for live loads, but if you are switching from drop to live, it might be true and confuse everyone
    fv.attachEmptyTrailerPickup = false;
  }
  return {
    ...copy,
    arrivalWindowMinutes: differenceInMinutes(
      stringOrDateToDate(fv.arrivalWindowEndsAt),
      stringOrDateToDate(fv.arrivalWindowStartsAt),
    ),
  };
};
