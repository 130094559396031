import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../../environments/environment';
import { filter, map } from 'rxjs/operators';

export interface CSVImport<T> {
  successful: boolean;
  data: CSVImportResponse<T>;
}

export interface CSVImportResponse<T> {
  custom_fields: CustomFields;
  destination_type: string;
  import_id: number;
  import_status: string;
  raw_file: string;
  row_count: number;
  row_fail: number;
  row_success: number;
  sheet_id: number;
  sheet_name: string;
  original_filename: string;
  external_validations_failed_requests: number;
  ev_failed: number;
  external_validation: number;
  import_starttime: number;
  import_endtime: number;
  env_name: string;
  import_description: string;
  column_mappings: ColumnMapping[];
  rows: T[];
  raw_columns: string[];
  ignored_columns: any[];
}

export interface CustomFields {
  name: string;
  id: string;
  company: string;
  companyName: string;
  user_id: string;
}

export interface ColumnMapping {
  loadNumber?: string;
  bookingNumber?: string;
  rtlDate?: string;
  erdDate?: string;
  docCutOffDate?: string;
  portCutoff?: string;
  pickupDay?: string;
}

@Component({
  selector: 'td-csv-importer-button',
  templateUrl: './csv-importer-button.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CsvImporterButtonComponent {
  private authService = inject(AuthService);
  public readonly env = {
    name: environment.name,
  };
  public user$ = this.authService.userInfo$.pipe(
    filter((v) => !!v),
    map((v) => {
      return {
        name: v.driverName,
        user_id: v.id,
        company: v.companyId,
        companyName: v.companyName,
      };
    }),
  );
  @Input() public licenseKey = '';
  @Output() public data = new EventEmitter<CSVImport<any>>();

  public onData(result: boolean, data: CSVImportResponse<any>) {
    this.data.next({
      successful: result,
      data,
    });
  }
}
