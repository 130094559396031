<div mat-dialog-title>
  <div class="flex justify-between items-center">Dispute Charges</div>
</div>
<div>
  <div mat-dialog-content>
    <mat-tab-group #tabs>
      <mat-tab [disabled]="disputedLumper" label="Lumper">
        <td-load-billiing-lumper-dispute
          [oldRate]="oldLumperRate"
          [load]="loadInDispute"
        ></td-load-billiing-lumper-dispute>
      </mat-tab>
      <mat-tab [disabled]="disputedLayover" label="Layover">
        <td-load-billiing-layover-dispute
          [oldRate]="oldLayoverRate"
          [load]="loadInDispute"
        ></td-load-billiing-layover-dispute>
      </mat-tab>
      <mat-tab [disabled]="disputedAdditionalStops" label="Stop Charges">
        <td-load-billiing-stop-charges-dispute [load]="loadInDispute"></td-load-billiing-stop-charges-dispute>
      </mat-tab>
      <mat-tab [disabled]="disputedLineHaul" label="LineHaul">
        <td-load-billiing-linehaul-dispute
          [oldRate]="oldLineHaulRate"
          [load]="loadInDispute"
        ></td-load-billiing-linehaul-dispute>
      </mat-tab>
      <mat-tab [disabled]="disputedDetention" label="Detention">
        <td-load-billiing-detention-dispute
          [oldRate]="oldDetentionRate"
          [load]="loadInDispute"
        ></td-load-billiing-detention-dispute>
      </mat-tab>
      <mat-tab [disabled]="disputedDeadhead" label="Deadhead">
        <td-load-billiing-deadhead-dispute
          [oldRate]="oldDeadheadRate"
          [load]="loadInDispute"
        ></td-load-billiing-deadhead-dispute>
      </mat-tab>
      <mat-tab [disabled]="disputedStopType" label="Stop Type">
        <td-load-billing-stop-type-dispute
          [oldRate]="oldLineHaulRate"
          [load]="loadInDispute"
        ></td-load-billing-stop-type-dispute>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
