<div class="flex flex-row items-center justify-between py-2">
  Which stops did you have detention at?
  <div class="flex-row items-center justify-between">
    <mat-selection-list [formControl]="stopsControl">
      <mat-list-option *ngFor="let stop of load.stops; trackBy: load.stops | trackByKey: 'id'" [value]="stop">
        {{ stop.sequence }} - {{ stop.address }} - {{ stop.facilityName }}
      </mat-list-option>
    </mat-selection-list>
  </div>
  <button color="primary" mat-raised-button (click)="disputeDetention($event)">Dispute Detention Charges</button>
</div>
<div *ngIf="responseGenerated" class="text-orange-500 text-lg">
  <ng-container *ngFor="let response of generatedResponses; trackBy: generatedResponses | trackByKey: 'indexOf'">
    {{ response }}
  </ng-container>
</div>
