<div class="flex flex-row items-center justify-between py-2">
  Which facility had live loading or unloading?
  <div class="p-2 flex flex-col flex-1">
    <mat-select placeholder="Select a facility" [formControl]="facilityControl">
      <mat-option *ngFor="let stop of load.stops; trackBy: load.stops | trackByKey: 'id'" [value]="stop.facilityId">
        {{ stop.facilityName }}
      </mat-option>
    </mat-select>
  </div>
  <button color="primary" mat-raised-button (click)="disputeStopType($event)">Dispute Stop Type</button>
</div>
<div *ngIf="stopTypePromopt" class="text-orange-500 text-lg">
  {{ stopTypePromopt }}
</div>
