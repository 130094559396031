import { Injectable } from '@angular/core';
import { SimpleFinn } from '../utilities/simpleFinn';
import { once, sort } from 'remeda';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { ContractRateType, EquipmentType, LongLat, SteamshipLine, TrailerType, Unit } from '../global-types';

export interface State {
  id: number;
  name: string;
  abbreviation: string;
}

export interface City {
  id: number;
  name: string;
  state: string;
  displayName: string;
}

export interface PreferenceListItem {
  id: number;
  label: string;
}

export interface DriverPreferenceSpecialLoadChoices {
  multiStopChoices: PreferenceListItem[];
  dropTrailerChoices: PreferenceListItem[];
  driverTouchChoices: PreferenceListItem[];
}

export interface TrailerTypeAttributeItem {
  type: string;
  label: string;
  choices: PreferenceListItem[];
}

export interface GetAdditionalEquipmentTypesResponse {
  choices: PreferenceListItem[];
}

export interface StateWithHeat {
  id: number;
  name: string;
  fullName: string;
  marketHeatReport: MarketHeatReport;
}

export interface MarketHeatReport {
  marketHeat: string;
  avgLoadCount: number;
  avgCentsPerMile: number;
  valid: boolean;
  updatedAt: string;
}

export interface NodeWithHeat {
  city: string;
  state: string;
  lngLat: LongLat;
  marketHeat: 'hot' | 'neutral' | 'cold';
  heatGradientValue: number;
  volumeGradientValue: number;
  profitGradientValue: number;
  updatedAt: string;
  destinationVolume: number;
  destinationVolume12Hours: number;
  destinationVolume24Hours: number;
  destinationVolume48Hours: number;
  destinationRPM: number;
  destinationProfitPerDay: number;
}

export interface HotLoadPossibleNextLane {
  originCity: string;
  originState: string;
  originLngLat: LongLat;
  destinationCity: string;
  destinationState: string;
  destinationLngLat: LongLat;
  laneRateCentsMedian: number;
  laneVolume12Hours: number;
  laneVolume24Hours: number;
  laneVolume48Hours: number;
  volumeGradientValue: number;
}

export interface NodeMarketHeatReport {
  trailerType: TrailerType;
  nodes: NodeWithHeat[];
  volumeLower: number;
  volumeUpper: number;
  heatLower: number;
  heatUpper: number;
  possibleNextLanes?: HotLoadPossibleNextLane[];
}

export interface BrokerRegion {
  id: number;
  name: string;
}

export interface SpecialRequirement {
  id: number;
  type: string;
  displayName: string;
}

export interface TrailerLeasingCompany {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface CommodityCertification {
  id: number;
  name: string;
}

interface ListTrailerLeasingCompaniesResponse {
  companies: TrailerLeasingCompany[];
}

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  private steamshipLines: SimpleFinn<SteamshipLine[]>;

  public get steamshipLines$(): Observable<SteamshipLine[]> {
    return this.steamshipLines.get$();
  }

  private units: SimpleFinn<Unit[]>;

  public get units$(): Observable<Unit[]> {
    return this.units.get$().pipe(map((value) => value.sort((a, b) => a.name.localeCompare(b.name))));
  }

  private statesWithHeat: SimpleFinn<StateWithHeat[]>;

  private allCities = new SimpleFinn<City[]>([], once(this.loadCities.bind(this)));

  public get allCities$(): Observable<City[]> {
    return this.allCities.get$();
  }

  public allCitiesMap$: Observable<Record<number, City>> = this.allCities$.pipe(
    map((cities) => {
      const cityMap: Record<number, City> = {};
      (cities || []).forEach((city) => {
        cityMap[city.id] = city;
      });
      return cityMap;
    }),
    shareReplay(1),
  );

  private allStates = new SimpleFinn<State[]>([], once(this.loadStates.bind(this)));

  public get allStates$(): Observable<State[]> {
    return this.allStates.get$().pipe(map(sort((a, b) => a.name.localeCompare(b.name))));
  }

  public allStatesMap$: Observable<Record<number, State>> = this.allStates$.pipe(
    map((states) => {
      const stateMap: Record<number, State> = {};
      (states || []).forEach((state) => {
        stateMap[state.id] = state;
      });
      return stateMap;
    }),
    shareReplay(1),
  );

  private driverReturnHomeChoices = new SimpleFinn<PreferenceListItem[]>(
    [],
    once(this.loadDriverHomeChoices.bind(this)),
  );

  public get driverReturnHomeChoices$(): Observable<PreferenceListItem[]> {
    return this.driverReturnHomeChoices.get$();
  }

  public driverReturnHomeChoicesMap$: Observable<Record<number, PreferenceListItem>> =
    this.driverReturnHomeChoices$.pipe(
      map((choices) => {
        const choiceMap: Record<number, PreferenceListItem> = {};
        (choices || []).forEach((choice) => {
          choiceMap[choice.id] = choice;
        });
        return choiceMap;
      }),
      shareReplay(1),
    );

  public driverPreferenceSpecialLoadChoices = new SimpleFinn<DriverPreferenceSpecialLoadChoices>(
    null,
    once(this.loadDriverPreferenceSpecialLoadChoices.bind(this)),
  );

  public get driverPreferenceSpecialLoadChoices$(): Observable<DriverPreferenceSpecialLoadChoices> {
    return this.driverPreferenceSpecialLoadChoices.get$();
  }

  public multiStopChoicesMap$: Observable<Record<number, PreferenceListItem>> =
    this.driverPreferenceSpecialLoadChoices$.pipe(
      map((allChoices) => {
        const choiceMap: Record<number, PreferenceListItem> = {};
        (allChoices?.multiStopChoices || []).forEach((choice) => {
          choiceMap[choice.id] = choice;
        });
        return choiceMap;
      }),
      shareReplay(1),
    );

  public dropTrailerChoicesMap$: Observable<Record<number, PreferenceListItem>> =
    this.driverPreferenceSpecialLoadChoices$.pipe(
      map((allChoices) => {
        const choiceMap: Record<number, PreferenceListItem> = {};
        (allChoices?.dropTrailerChoices || []).forEach((choice) => {
          choiceMap[choice.id] = choice;
        });
        return choiceMap;
      }),
      shareReplay(1),
    );

  public driverTouchChoicesMap$: Observable<Record<number, PreferenceListItem>> =
    this.driverPreferenceSpecialLoadChoices$.pipe(
      map((allChoices) => {
        const choiceMap: Record<number, PreferenceListItem> = {};
        (allChoices?.driverTouchChoices || []).forEach((choice) => {
          choiceMap[choice.id] = choice;
        });
        return choiceMap;
      }),
      shareReplay(1),
    );

  private trailerTypeAttributes = new SimpleFinn<TrailerTypeAttributeItem[]>(
    [],
    once(this.loadDriverPreferenceTrailerTypeAttributes.bind(this)),
  );

  public get trailerTypeAttributes$(): Observable<TrailerTypeAttributeItem[]> {
    return this.trailerTypeAttributes.get$();
  }

  public trailerTypeAttributesMap$: Observable<Record<string, Record<number, PreferenceListItem>>> =
    this.trailerTypeAttributes$.pipe(
      map((trailers) => {
        const trailerMap: Record<string, Record<number, PreferenceListItem>> = {};
        (trailers || []).forEach((trailer) => {
          const choiceMap: Record<number, PreferenceListItem> = {};
          (trailer.choices || []).forEach((choice) => {
            choiceMap[choice.id] = choice;
          });
          trailerMap[trailer.type] = choiceMap;
        });
        return trailerMap;
      }),
      shareReplay(1),
    );

  private additionalEquipmentTypes = new SimpleFinn<PreferenceListItem[]>(
    [],
    once(this.loadAdditionalEquipmentTypes.bind(this)),
  );

  public get additionalEquipmentTypes$(): Observable<PreferenceListItem[]> {
    return this.additionalEquipmentTypes.get$();
  }

  private specialRequirements = new SimpleFinn<SpecialRequirement[]>([], once(this.loadSpecialRequirements.bind(this)));

  public get specialRequirements$(): Observable<SpecialRequirement[]> {
    return this.specialRequirements.get$();
  }

  private trailerLeasingCompanies: SimpleFinn<TrailerLeasingCompany[]>;
  public get trailerLeasingCompanies$(): Observable<TrailerLeasingCompany[]> {
    return this.trailerLeasingCompanies.get$();
  }

  private carrierCommodityCertifications: SimpleFinn<CommodityCertification[]>;
  public get carrierCommodityCertifications$(): Observable<CommodityCertification[]> {
    return this.carrierCommodityCertifications.get$();
  }

  constructor(private http: HttpClient) {
    this.steamshipLines = new SimpleFinn<SteamshipLine[]>([], this.loadSteamshipLines);
    this.units = new SimpleFinn<Unit[]>([], this.loadUnits);
    this.trailerLeasingCompanies = new SimpleFinn<TrailerLeasingCompany[]>([], this.loadCompaniesList);
    this.carrierCommodityCertifications = new SimpleFinn<CommodityCertification[]>(
      [],
      this.loadCarrierCommodityCertifications,
    );
  }

  private loadCities(): Promise<City[]> {
    return lastValueFrom(this.http.get<City[]>(`${environment.api}/v1/cities`));
  }

  private loadStates(): Promise<State[]> {
    return lastValueFrom(this.http.get<State[]>(`${environment.api}/v1/states`));
  }

  private loadDriverHomeChoices(): Promise<PreferenceListItem[]> {
    return lastValueFrom(
      this.http
        .get<{ choices: PreferenceListItem[] }>(
          `${environment.api}/v2/max_payloop/driver_preference/return_home_choices`,
        )
        .pipe(map((result) => result?.choices || [])),
    );
  }

  private loadDriverPreferenceSpecialLoadChoices(): Promise<DriverPreferenceSpecialLoadChoices> {
    return lastValueFrom(
      this.http.get<DriverPreferenceSpecialLoadChoices>(
        `${environment.api}/v2/max_payloop/driver_preference/special_loads_choices`,
      ),
    );
  }

  private loadDriverPreferenceTrailerTypeAttributes(): Promise<TrailerTypeAttributeItem[]> {
    return lastValueFrom(
      this.http
        .get<{ trailerTypeAttributes: TrailerTypeAttributeItem[] }>(
          `${environment.api}/v2/max_payloop/driver_preference/trailer_type_attributes`,
        )
        .pipe(map((result) => result?.trailerTypeAttributes || [])),
    );
  }

  private loadAdditionalEquipmentTypes(): Promise<PreferenceListItem[]> {
    return lastValueFrom(
      this.http
        .get<GetAdditionalEquipmentTypesResponse>(`${environment.api}/v2/max_payloop/additional_equipment_types`)
        .pipe(map((response) => response.choices)),
    );
  }

  private equipments: SimpleFinn<EquipmentType[]> = new SimpleFinn<EquipmentType[]>(
    [],
    once(this.loadEquipment.bind(this)),
  );

  public get equipments$(): Observable<EquipmentType[]> {
    return this.equipments.get$();
  }

  private loadEquipment(): Promise<EquipmentType[]> {
    return lastValueFrom(
      this.http
        .get<{ equipmentTypes: EquipmentType[] }>(`${environment.api}/v2/max_payloop/equipments`)
        .pipe(map((v) => v.equipmentTypes || [])),
    );
  }

  private contractRateTypes: SimpleFinn<ContractRateType[]> = new SimpleFinn<ContractRateType[]>(
    [],
    once(this.loadContractRateTypes.bind(this)),
  );

  public get contractRateTypes$(): Observable<ContractRateType[]> {
    return this.contractRateTypes.get$();
  }

  private loadContractRateTypes(): Promise<ContractRateType[]> {
    return lastValueFrom(
      this.http
        .get<{ types: ContractRateType[] }>(`${environment.api}/v2/max_payloop/contract_rate_types`)
        .pipe(map((v) => v.types || [])),
    );
  }

  public loadStatesWithHeat = async (trailerType?: string) => {
    try {
      return await lastValueFrom(
        this.http
          .get<{ states: StateWithHeat[] }>(`${environment.api}/v2/max_payloop/states_market_heat`, {
            params: trailerType ? { trailerType } : {},
          })
          .pipe(map((v) => v?.states || [])),
      );
    } catch (e) {
      return null;
    }
  };
  public loadSteamshipLines = async (): Promise<SteamshipLine[]> => {
    try {
      return await lastValueFrom(
        this.http
          .get<{ steamshipLines: SteamshipLine[] }>(`${environment.api}/v2/admin/street_turn/steamship_lines`)
          .pipe(
            map((v) => v?.steamshipLines || []),
            map(sort((a, b) => a.name.localeCompare(b.name))),
          ),
      );
    } catch (e) {
      return [];
    }
  };

  private loadUnits = (): Promise<Unit[]> => {
    return lastValueFrom(
      this.http
        .get<{ units: Unit[] }>(`${environment.api}/v2/max_payloop/dispatcher/units`)
        .pipe(map((v) => v?.units || [])),
    );
  };

  private loadSpecialRequirements(): Promise<SpecialRequirement[]> {
    return lastValueFrom(
      this.http
        .get<{ specialRequirements: SpecialRequirement[] }>(
          `${environment.api}/v2/max_payloop/dispatcher/special_requirements`,
        )
        .pipe(map((response) => response?.specialRequirements ?? [])),
    );
  }

  private loadCompaniesList = async (): Promise<TrailerLeasingCompany[]> => {
    return lastValueFrom(
      this.http
        .get<ListTrailerLeasingCompaniesResponse>(`${environment.api}/v2/vpf/internal/trailer_leasing_company`)
        .pipe(map((v) => v?.companies ?? [])),
    );
  };

  private loadCarrierCommodityCertifications = () => {
    return lastValueFrom(
      this.http
        .get<{ certifications: CommodityCertification[] }>(
          `${environment.api}/v2/vpf/company_admin/commodity_certifications`,
        )
        .pipe(map((response) => response?.certifications ?? [])),
    );
  };
}
