<div class="flex flex-row items-center justify-between py-2">
  <button color="primary" mat-raised-button (click)="disputeDeadhead($event)">Dispute Deadhead Charges</button>
  <div *ngIf="disputeResponse">
    <div class="flex flex-col text-lg text-orange-500 ml-1">
      <ng-container *ngIf="deadHeadPayout > 0; else payout">
        Deadhead will be added to the payout. Deadhead payout is {{ deadHeadPayout | currency }}
      </ng-container>
      <ng-template #payout>
        Deadhead will not be added to the payout. Because you either did not exceed the minimum deadhead miles or you
        did not use the application.
      </ng-template>
    </div>
  </div>
</div>
