import { Injectable } from '@angular/core';
import { DISPATCH_POOL_ID, UFG_V3_LOAD_ID } from './dispatch-pool';
import { firstValueFrom, lastValueFrom, Observable, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RouterStateService } from '../../../shared/services/router-state.service';
import { environment } from '../../../../environments/environment';
import { RouterFinn } from '../../../shared/utilities/routerFinn';
import { map } from 'rxjs/operators';
import { SimpleFinn } from '../../../shared/utilities/simpleFinn';
import { PoolWeights, UFGCarrierMetrics, XYPoint } from '../../../shared/global-types';

export interface UFGV3LoadStatusHistory {}

export interface UFGV3LoadHistoryAndDetails {
  loadStatusHistory: UFGV3LoadStatusHistory | null;
  loadDetails: UFGLoadDetails;
}

export interface UFGCarrierInPool {
  carrierId: string;
  name: string;
  dotNumber: string;
  totalLoadsClaimed: number;
  totalLoadsReturned: number;
  totalLoadsCompleted: number;
  totalLoadsPending: number;
  totalLoadsInProgress: number;
  carrierMetrics?: UFGCarrierMetrics;
}

export interface UFGLoadStatusInfo {
  id: number;
  loadId: string;
  driverId: string;
  actorId: string;
  carrierId: string;
  ufgStatus: string;
  overbooked: boolean;
  notes: string | null;
  isLatest: boolean;
  createdAt: string;
  loadStatus: string;
  trailerType: string;
  firstStopArrivalStart: string;
  firstStopArrivalEnd: string;
  firstStopAddress: string;
  lastStopArrivalStart: string;
  lastStopArrivalEnd: string;
  lastStopAddress: string;
  driverName: string;
  carrierName: string;
  actorName: string;
}

export interface LoadDetailsStop {
  id: number;
  loadId: string;
  type: string;
  sequence: number;
  title: string;
  completedAt: string | null;
  arrivedAt: string | null;
  exitedAt: string | null;
  lngLat: {
    longitude: number;
    latitude: number;
  };
}

export interface UFGLoadDetails {
  loadId: string;
  status: string;
  calculatedStatus: string;
  customerLoadNumber: string;
  trailerType: string;
  driverId: string;
  driverName: string;
  emptyAssetFacilityName: string;
  emptyAssetPickupEarliest: string;
  emptyAssetPickupLatest: string;
  pickupFacilityName: string;
  pickupTimeEarliest: string;
  pickupTimeLatest: string;
  dropoffFacilityName: string;
  dropoffTimeEarliest: string;
  dropoffTimeLatest: string;
  stops: LoadDetailsStop[];
}

export interface UFGLoadListItem {
  loadStatusInfo: UFGLoadStatusInfo;
  loadDetails: UFGLoadDetails;
  lineHaulCents: number;
  brokerCharge: number;
  poolId: number;
  poolName: string;
  warnings: string[];
}

export interface SpotLoadPrecheck {
  loadId: string;
  isInUFGPipeline: boolean;
  canBeMarkedUFG: boolean;
  warnings: string[];
}

export interface SpotLoadLane {
  laneID: number;
  originFacilityID: string;
  originFacilityName: string;
  originFacilityCustomerReferenceNumber: string;
  originFacilityLngLat: XYPoint;
  destinationFacilityID: string;
  destinationFacilityName: string;
  destinationFacilityLngLat: XYPoint;
  destinationFacilityCustomerReferenceNumber: string;
  miles: number;
}

@Injectable({
  providedIn: 'root',
})
export class DispatchPoolUfgV3Service {
  private readonly dispatchPoolId$ = this.routerStateService
    .listenForParamChange$(DISPATCH_POOL_ID)
    .pipe(shareReplay(1));

  private carriersInDispatchPool = new RouterFinn<UFGCarrierInPool[]>([], this.dispatchPoolId$, (id: string) =>
    this.getCarriersInDispatchPool(+id),
  );

  public get carriersInDispatchPool$() {
    return this.carriersInDispatchPool.get$();
  }

  public carriersInDispatchPoolLoading$ = this.carriersInDispatchPool.networkActive$.pipe(shareReplay(1));

  private poolWeights = new RouterFinn<PoolWeights>(
    null,
    this.dispatchPoolId$,
    (id: string) => this.getPoolWeights(+id),
    false,
  );

  public get poolWeights$() {
    return this.poolWeights.get$();
  }

  public get poolWeightsLoading$() {
    return this.poolWeights.networkActive$;
  }

  private lanes = new RouterFinn<SpotLoadLane[]>([], this.dispatchPoolId$, (id: string) => this.getLanes(id));

  public get lanes$() {
    return this.lanes.get$();
  }

  constructor(private httpClient: HttpClient, private routerStateService: RouterStateService) {}

  public async addCarrierToPool(carrierId: string): Promise<boolean> {
    try {
      const dispatchPoolId = +(await firstValueFrom(this.dispatchPoolId$));
      await firstValueFrom(
        this.httpClient.post(`${environment.api}/v2/vpf/internal/ufgv3/${dispatchPoolId}/add_carrier`, {
          carrierId,
        }),
      );
      this.carriersInDispatchPool.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async removeCarrierFromPool(carrierId: string): Promise<boolean> {
    const dispatchPoolId = +(await firstValueFrom(this.dispatchPoolId$));
    try {
      await firstValueFrom(
        this.httpClient.post(`${environment.api}/v2/vpf/internal/ufgv3/${dispatchPoolId}/remove_carrier`, {
          carrierId,
        }),
      );
      this.carriersInDispatchPool.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  private async getCarriersInDispatchPool(dispatchPoolId: number): Promise<UFGCarrierInPool[]> {
    return firstValueFrom(
      this.httpClient
        .get<{
          carriers: UFGCarrierInPool[];
        }>(`${environment.api}/v2/vpf/internal/ufgv3/${dispatchPoolId}/carriers`)
        .pipe(map((res) => res.carriers || [])),
    );
  }

  public async getCandidateCarriers$(searchString: string): Promise<UFGCarrierInPool[]> {
    const dispatchPoolId = +(await firstValueFrom(this.dispatchPoolId$));
    return firstValueFrom(
      this.httpClient
        .get<{
          carriers: UFGCarrierInPool[];
        }>(`${environment.api}/v2/vpf/internal/ufgv3/${dispatchPoolId}/candidate_carriers`, {
          params: {
            searchString,
          },
        })
        .pipe(map((res) => res.carriers)),
    );
  }

  public async markLoadAsSpotLoad(loadId: string, notes: string = null): Promise<boolean> {
    try {
      await firstValueFrom(
        this.httpClient.post(`${environment.api}/v2/vpf/internal/ufgv3/${loadId}/mark_as_ufg`, {
          notes,
        }),
      );
      return true;
    } catch (e) {
      return false;
    }
  }

  public async getSpotLoadPrecheck(loadId: string): Promise<SpotLoadPrecheck> {
    return firstValueFrom(
      this.httpClient
        .get<{
          spotLoadPrecheck: SpotLoadPrecheck;
        }>(`${environment.api}/v2/vpf/internal/ufgv3/${loadId}/check_ufg`)
        .pipe(map((res) => res.spotLoadPrecheck)),
    );
  }

  public async removeFromSpotLoads(loadId: string) {
    try {
      await firstValueFrom(
        this.httpClient.post(`${environment.api}/v2/vpf/internal/ufgv3/${loadId}/remove_from_ufg`, {}),
      );
      return true;
    } catch (e) {
      return false;
    }
  }

  private async getPoolWeights(poolID: number) {
    return firstValueFrom(
      this.httpClient.get<PoolWeights>(`${environment.api}/v2/vpf/internal/ufgv3/${poolID}/carrier_kpi_weights`),
    );
  }

  public async updatePoolWeights(poolWeights: PoolWeights) {
    const dispatchPoolId = +(await firstValueFrom(this.dispatchPoolId$));
    try {
      await firstValueFrom(
        this.httpClient.put(
          `${environment.api}/v2/vpf/internal/ufgv3/${dispatchPoolId}/carrier_kpi_weights`,
          poolWeights,
        ),
      );
      this.poolWeights.refresh();
      this.carriersInDispatchPool.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  private getLanes(id: string) {
    return firstValueFrom(
      this.httpClient
        .get<{ lanes: SpotLoadLane[] }>(`${environment.api}/v2/vpf/internal/ufgv3/${id}/lanes`)
        .pipe(map((res) => res.lanes)),
    );
  }

  public async createLane(value: any) {
    const dispatchPoolId = await firstValueFrom(this.dispatchPoolId$);
    try {
      await firstValueFrom(
        this.httpClient.put(`${environment.api}/v2/vpf/internal/ufgv3/${dispatchPoolId}/lanes`, value),
      );
      this.lanes.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async deleteLane(laneID: number) {
    const dispatchPoolId = await firstValueFrom(this.dispatchPoolId$);
    try {
      await firstValueFrom(
        this.httpClient.delete(`${environment.api}/v2/vpf/internal/ufgv3/${dispatchPoolId}/lanes`, {
          body: {
            laneID,
          },
        }),
      );
      this.lanes.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }
}
