import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadBillingService } from '../../../../services/load-billing.service';
import { TourLoadCharge } from '../../../../../modules/carrier/carrier-loads/carrier-loads.service';
import { load } from '@angular-devkit/build-angular/src/utils/server-rendering/esm-in-memory-file-loader';
import { LohiLoad } from '../../../../global-types';

@Component({
  selector: 'td-load-billiing-linehaul-dispute',
  templateUrl: './load-billiing-linehaul-dispute.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadBilliingLinehaulDisputeComponent {
  @Input() public load: LohiLoad;
  @Input() public oldRate: number;
  public loadType: string;
  public lineHaulPayout: number;
  public lineHaulDispute: boolean = false;
  constructor(private lbs: LoadBillingService, private cdr: ChangeDetectorRef) {}
  public async disputeLineHaul(event: Event) {
    this.determineLoadType();
    const resp = await this.lbs.runDisputeLinehaul(this.load.id, this.oldRate);
    this.lineHaulPayout = resp['cents'] / 100;
    this.lineHaulDispute = true;
    this.cdr.detectChanges();
  }

  private determineLoadType() {
    var liveLoad = 0;
    var dropLoad = 0;
    for (var i = 0; i < this.load.stops.length; i++) {
      if (this.load.stops[i].loadingType === 'live') {
        liveLoad++;
      } else {
        dropLoad++;
      }
    }
    if (liveLoad == 0) {
      this.loadType = 'Drop Load';
      return;
    }
    if (dropLoad == 0) {
      this.loadType = 'Live Load';
      return;
    }
    if (liveLoad > 0 && dropLoad > 0) {
      this.loadType = 'Drop Live Load';
    }
  }
}
