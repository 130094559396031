import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { LohiLoad } from '../../../../global-types';
import { LoadBillingService } from '../../../../services/load-billing.service';

@Component({
  selector: 'td-load-billiing-layover-dispute',
  templateUrl: './load-billiing-layover-dispute.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadBilliingLayoverDisputeComponent {
  @Input() public load: LohiLoad;
  @Input() public oldRate: number;
  public loadType: string;
  public layoverPrompt: string;
  constructor(private lbs: LoadBillingService, private cdr: ChangeDetectorRef) {}
  public async disputeLayover(event: Event) {
    const resp = await this.lbs.runDisputeLayover(this.load.id, this.oldRate);
    if (!resp['driverUsedApp']) {
      this.layoverPrompt = resp['note'];
    } else {
      if (!resp['wasLayover']) {
        this.layoverPrompt = resp['note'];
      } else {
        if (resp['allowed']) {
          this.layoverPrompt = `Layover has been approved. You will be paid $${resp['addedCents'] / 100}`;
        } else {
          this.layoverPrompt = resp['note'];
        }
      }
    }
    this.cdr.detectChanges();
  }
}
