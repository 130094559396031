// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const apiKey = 'TD_API_URL';
const defaultApiUrl = 'https://stage.api.lohi.ai';

export const environment = {
  production: false,
  name: 'stage',
  get api(): string {
    return getSessionThenLocalWithDefault(apiKey, defaultApiUrl);
  },
  get streamChatAPIKey(): string {
    return 'r32nvk36vzpc';
  },
  get lohiWeb(): string {
    return 'https://stage.web.lohi.ai';
  },
  customerWeb: 'https://stage.customer.5f.app',
  intercomId: null,
  get maintenanceApi(): string {
    return 'https://dev-maintenance.api.5f.app';
  },
  get maintenanceWeb(): string {
    return 'https://stage-vorto-maint-tickets.web.app';
  },
  launchDarklyClientSideId: '6674c020bbbf430facceee32',
};

const getSessionThenLocalWithDefault = (key: string, defaultValue: string): string => {
  const sessionValue = sessionStorage.getItem(key);
  if (sessionValue) {
    return sessionValue;
  }

  const localValue = localStorage.getItem(key);
  if (localValue) {
    return localValue;
  }

  return defaultValue;
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
